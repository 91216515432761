import Placeholders from '../../components/placeholders/placeholders';
import Slider from '../../components/slider/slider';

import Scroll from '../../utils/scroll';

import { constants, instances } from '../../store';

// import '../../styles/index.scss'

constants.isDevice = 'ontouchstart' in window;
constants.isDevice && document.body.classList.add('is-device');

// set vh css var
document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

instances.scroll = new Scroll();
instances.scroll.init();

const placeholders = new Placeholders();
placeholders.init();

instances.slider = new Slider();
instances.slider.init();

/*--added on 221103 --*/
import gsap from 'gsap';

gsap.fromTo(".logo, .nav, .menu_trigger, .content_top, .js-content", 2, {
    opacity: 0,
}, {
    // delay: 1,
    opacity: 1,
    // ease: "expo.inOut",
});

gsap.fromTo(".rev", 2.5, {
    opacity: 0,
    yPercent: 100,
    skewY: 30
}, {
    // delay: 1,
    opacity: 1,
    yPercent: 0,
    skewY: 0,
    ease: "expo.inOut",
    stagger: .05
});
/*--/added on 221103 --*/

gsap.fromTo(".rev2", 2.5, {
    opacity: 0,
    yPercent: 100,
    skewY: -30
}, {
    // delay: 1,
    opacity: 1,
    yPercent: 0,
    skewY: 0,
    ease: "expo.inOut",
    stagger: .05
});

var nav_circle = document.querySelectorAll('.nav ul li');
nav_circle.forEach(function(target) {
    target.addEventListener('mouseover', function() {
        target.classList.add('is-show');
    })
    target.addEventListener('mouseleave', function() {
        target.classList.remove('is-show');
    })
});

const logo_projects = document.querySelector('.logo_projects');
if (logo_projects !== null) {
    gsap.fromTo(".logo_projects", 1, {
        opacity: 0,
    }, {
        delay: 1.7,
        opacity: 1,
        // ease: "expo.inOut",
    });
}